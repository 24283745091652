import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { share } from "rxjs/operators";
import { SectionMenu } from "../model/sectionMenu.model.js";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  collapsed = true;
  sectionMenuData: SectionMenu[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http
      .get<SectionMenu[]>(
        "../../assets/db-data/db-data-sportangebote-menu.json"
      )
      .pipe(share())
      .subscribe((sectionMenuData) => (this.sectionMenuData = sectionMenuData));
  }
}
