import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  
  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  onSubmit(form: NgForm) {
  }

  onHandleError() {
  
  }
}
