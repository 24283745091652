import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { Section } from "../model/section.model";

@Injectable({
  providedIn: "root",
})
export class SectionService {
  constructor(private http: HttpClient) {}

  getSections() {
    return this.loadSections();
  }

  getSection(id: string) {
    return this.loadSections().pipe(
      map((sections) => sections.find((item) => item.id === id))
    );
  }

  private loadSections(): Observable<Section[]> {
    return this.http
      .get<Section[]>("../../assets/db-data/db-data-sportangebote.json")
      .pipe(share());
  }
}
