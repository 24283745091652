import { Component, OnInit } from '@angular/core';
import sponsorData from '../../assets/db-data/db-data-sponsoren.json';
import { SponsorList } from '../model/sponsor-list.model.js';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit {

  sponsors: SponsorList = null;
  constructor() { }

  ngOnInit() {
    if (this.sponsors === null) {
      this.sponsors = sponsorData[0];
    }
  }
}
