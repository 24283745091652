import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApproachComponent } from './approach/approach.component';
import { AuthComponent } from './auth/auth.component';
import { BoardComponent } from './board/board.component';
import { ContactComponent } from './contact/contact.component';
import { DocumentsComponent } from './documents/documents.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { MainComponent } from './main/main.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { SectionDetailComponent } from './section/section-detail/section-detail.component';
import { SectionComponent } from './section/section.component';
import { SponsorComponent } from './sponsor/sponsor.component';

const routes: Routes = [
  { path: '', redirectTo: '/aktuelles', pathMatch: 'full' },
  { path: 'aktuelles', component: MainComponent},
  { path: 'verein/vorstand', component: BoardComponent},
  { path: 'verein/dokumente', component: DocumentsComponent},
  { path: 'sportangebot', component: SectionComponent, children: [
    { path: ':id', component: SectionDetailComponent} 
    ]
  },
  { path: 'sponsoren', component: SponsorComponent},
  { path: 'kontakt/ansprechpartner', component: ContactComponent},
  { path: 'kontakt/anfahrt', component: ApproachComponent},
  { path: 'kontakt/impressum', component: ImpressumComponent},
  { path: 'kontakt/datenschutz', component: PrivacyStatementComponent},
  { path: 'login', component: AuthComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
