import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { share } from "rxjs/operators";
import { BoardMemeber } from "../model/boardMember.model.js";

@Component({
  selector: "app-board",
  templateUrl: "./board.component.html",
  styleUrls: ["./board.component.scss"],
})
export class BoardComponent implements OnInit {
  boards: BoardMemeber[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    return this.http
      .get<BoardMemeber[]>("../../assets/db-data/db-data-vorstand.json")
      .pipe(share())
      .subscribe((board) => (this.boards = board));
  }
}
