import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';

import { NewsService } from '../news/news.service';
import { News } from '../model/news.model';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  constructor(private http: HttpClient, private newsService: NewsService) { }

  fetchNews() {

    return this.http.get<News[]>('https://sportclub-osterbrock.de/intern/index.php?rest_route=/wp/v2/posts')
    .pipe(
      map(newsList => {
        return newsList.map(newsItem => {
          return {...newsItem};
        });
      }),
      tap(news => {
        this.newsService.setNews(news);
      }),
      catchError(error => {
        return error;
      })
    );
  }
}
