import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { share } from "rxjs/operators";
import { Document } from "../model/document.model.js";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnInit {
  fileFolder = "/assets/downloads/";
  documents: Document[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    return this.http
      .get<Document[]>("../../assets/db-data/db-data-dokumente.json")
      .pipe(share())
      .subscribe((documents) => (this.documents = documents));
  }
}
