import { Component, OnInit } from '@angular/core';
import { SectionService } from '../../service/section.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Section } from 'src/app/model/section.model';

@Component({
  selector: 'app-section-detail',
  templateUrl: './section-detail.component.html',
  styleUrls: ['./section-detail.component.scss']
})
export class SectionDetailComponent implements OnInit {

  section: Section;
  id: string;

  constructor(private sectionService: SectionService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe (
      (params: Params) => {
        this.id = params['id'];
        this.sectionService.getSection(this.id).subscribe(section => {
          this.section = section;
        });
      }
    );
  }

}
