import { Component, OnInit, Input } from "@angular/core";
import { Section } from "../model/section.model";
import { SectionService } from "../service/section.service";

@Component({
  selector: "app-section",
  templateUrl: "./section.component.html",
  styleUrls: ["./section.component.scss"],
})
export class SectionComponent implements OnInit {
  sections: Section[];

  constructor(private sectionService: SectionService) {}

  ngOnInit() {
    this.sectionService.getSections().subscribe((sections) => {
      this.sections = sections;
    });
  }
}
