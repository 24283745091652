import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-route.routing";
import { AppComponent } from "./app.component";
import { ApproachComponent } from "./approach/approach.component";
import { AuthComponent } from "./auth/auth.component";
import { BoardComponent } from "./board/board.component";
import { ClubComponent } from "./club/club.component";
import { ContactComponent } from "./contact/contact.component";
import { DocumentsComponent } from "./documents/documents.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HistoryComponent } from "./history/history.component";
import { ImpressumComponent } from "./impressum/impressum.component";
import { MainComponent } from "./main/main.component";
import { MembershipComponent } from "./membership/membership.component";
import { NewsComponent } from "./news/news.component";
import { PrivacyStatementComponent } from "./privacy-statement/privacy-statement.component";
import { SchudelerComponent } from "./schudeler/schudeler.component";
import { SectionComponent } from "./section/section.component";
import { SponsorComponent } from "./sponsor/sponsor.component";
import { SectionDetailComponent } from "./section/section-detail/section-detail.component";
import { SubmenuComponent } from "./submenu/submenu.component";
import { SafeUrlPipe } from "./shared/safe-url.pipe";
import { CookieLawModule } from "angular2-cookie-law";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    NewsComponent,
    SchudelerComponent,
    BoardComponent,
    HistoryComponent,
    MembershipComponent,
    DocumentsComponent,
    SectionComponent,
    SponsorComponent,
    ContactComponent,
    ApproachComponent,
    ImpressumComponent,
    PrivacyStatementComponent,
    AuthComponent,
    ClubComponent,
    SectionDetailComponent,
    SubmenuComponent,
    SafeUrlPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    CookieLawModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
