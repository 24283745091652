import { Component, OnInit } from "@angular/core";
import sectionSubMenuData from "../../assets/db-data/db-data-sportangebote-submenu.json";
import { SectionSubMenu } from "../model/sectionSubMenu.model.js";
import { ActivatedRoute, Params } from "@angular/router";
import { Section } from "../model/section.model.js";
import { SectionService } from "../service/section.service.js";
import { Subscription } from "rxjs";

@Component({
  selector: "app-submenu",
  templateUrl: "./submenu.component.html",
  styleUrls: ["./submenu.component.scss"],
})
export class SubmenuComponent implements OnInit {
  private subMenuData: SectionSubMenu[] = [];
  private id: string;
  private section: Section;
  currentSubMenu: SectionSubMenu = null;

  subSection: Subscription;

  constructor(
    private sectionService: SectionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.subMenuData.length === 0) {
      this.subMenuData = sectionSubMenuData;
    }

    this.route.params.subscribe((params: Params) => {
      this.id = params["id"];

      this.subSection = this.sectionService
        .getSection(this.id)
        .subscribe((section) => {
          this.section = section;
        });

      this.subSection.unsubscribe();

      if (this.section != null) {
        this.currentSubMenu = this.subMenuData.find(
          (item) => item.id === this.section.subMenuId
        );
      }
    });
  }
}
