import { Component, OnInit } from "@angular/core";
import { Datenschutz } from "../model/datenschutz.model.js";
import { HttpClient } from "@angular/common/http";
import { share } from "rxjs/operators";

@Component({
  selector: "app-privacy-statement",
  templateUrl: "./privacy-statement.component.html",
  styleUrls: ["./privacy-statement.component.scss"],
})
export class PrivacyStatementComponent implements OnInit {
  datenschutz: Datenschutz = null;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    return this.http
      .get<Datenschutz[]>("../../assets/db-data/db-data-datenschutz.json")
      .pipe(share())
      .subscribe((datenschutz) => (this.datenschutz = datenschutz[0]));
  }
}
