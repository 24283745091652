import { Component, OnInit } from "@angular/core";
import { SectionService } from "../service/section.service";
import { Section } from "../model/section.model";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  sections: Section[];
  defaultMail: "vorstand@sc-osterbrock.de";

  constructor(private sectionService: SectionService) {}

  ngOnInit() {
    this.sectionService.getSections().subscribe((sections) => {
      this.sections = sections;
    });
  }
}
