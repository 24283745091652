import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { News } from '../model/news.model';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor() { 
  }

  newsChangedEvent = new Subject<News[]>();

  private news: News[] = [];

  setNews(news: News[]) {
    this.news = news;
    this.newsChangedEvent.next(this.news.slice());
  }

  getNews() {
    return this.news.slice(); // Kopie ausgeben
  }
}
