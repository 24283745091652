import { Component, OnInit } from "@angular/core";
import { Impressum } from "../model/impressum.model.js";
import { share } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-impressum",
  templateUrl: "./impressum.component.html",
  styleUrls: ["./impressum.component.scss"],
})
export class ImpressumComponent implements OnInit {
  impressum: Impressum = null;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    return this.http
      .get<Impressum[]>("../../assets/db-data/db-data-impressum.json")
      .pipe(share())
      .subscribe((impressum) => (this.impressum = impressum[0]));
  }
}
