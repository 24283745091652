import { Component, OnInit, OnDestroy } from '@angular/core';
import { News } from '../model/news.model';
import { Subscription } from 'rxjs';
import { NewsService } from './news.service';
import { DataStorageService } from '../shared/data-storage.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  news: News[];
  subscriptionNews: Subscription;
  subscriptionDataStore: Subscription;

  constructor(private dataStorageService: DataStorageService, private newsService: NewsService) { }

  ngOnInit() {

    this.subscriptionNews = this.newsService.newsChangedEvent.subscribe(
      (news: News[]) => {
        this.news = news;
      }
    );

    this.subscriptionDataStore = this.dataStorageService.fetchNews().subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptionNews.unsubscribe();
    this.subscriptionDataStore.unsubscribe();
  }
}